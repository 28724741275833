import React, { useState, useEffect } from "react";
import moment from "moment";
import styles from "./EventCreate.module.scss";
import { Container } from "../common/Container";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "react-toastify/dist/ReactToastify.css";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import API_BASE_URL from "../../Utils/Utils";
import Loader from "../Loader";

const EventCreate = () => {
  const [formData, setFormData] = useState({
    eventName: "",
    host: "",
    prizeMoney: "",
    registrationFee: "",
    registrationLink: "",
    ruleBook: "",
    sponsorDetails: "",
    eventDetails: "",
    otherDetails: "",
    eventStart: null,
    eventEnd: null,
    selectedCategory: "",
  });
  const [preview, setPreview] = useState(null);
  const [eventCategories, setEventCategories] = useState([]);
  const [milestones, setMilestones] = useState([
    { id: 1, dateTime: null, heading: "", description: "" },
  ]);
  const [filesRequired, setFilesRequired] = useState([
    { id: 1, fileName: "", formats: [], description: "" },
  ]);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.alert("Please login to access this page.");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    // Function to fetch event categories
    const fetchEventCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/getAllEventCategories`
        );
        if (response.data.status === 200) {
          const transformedData = response.data.data.map((category) => ({
            value: category.event_category,
            label: category.event_category,
          }));
          setEventCategories(transformedData);
          setLoading(false);
          // toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } catch (error) {
        toast.error(error);
        setLoading(false);
      }
    };

    // Call fetchEventCategories function when component mounts
    fetchEventCategories();

    // Clean-up function (optional)
    return () => {
      // Any clean-up code here, if needed
    };
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const handleStartDateChange = (date) => {
    setFormData({
      ...formData,
      eventStart: date,
    });
  };

  const handleEndDateChange = (date) => {
    setFormData({
      ...formData,
      eventEnd: date,
    });
  };

  const names = [
    "PDF",
    "Word Document",
    "Audio",
    "Video",
    "Mp3",
    "Wav",
    "Mp4",
    "JPEG/JPG",
    "PNG",
  ];
  //milestones--

  const handleMilestoneChange = (index, field, value) => {
    const updatedMilestones = milestones.map((milestone, i) =>
      i === index ? { ...milestone, [field]: value } : milestone
    );
    setMilestones(updatedMilestones);
  };

  const handleAddMilestone = () => {
    setMilestones([
      ...milestones,
      {
        id: milestones.length + 1,
        dateTime: null,
        heading: "",
        description: "",
      },
    ]);
  };

  const handleRemoveMilestone = (id) => {
    setMilestones(milestones.filter((milestone) => milestone.id !== id));
  };

  //Files--

  const handleFileChange = (index, field, value) => {
    const updatedFiles = filesRequired.map((file, i) =>
      i === index ? { ...file, [field]: value } : file
    );
    setFilesRequired(updatedFiles);
  };

  const handleAddClickFile = () => {
    setFilesRequired([
      ...filesRequired,
      {
        id: filesRequired.length + 1,
        fileName: "",
        formats: [],
        description: "",
      },
    ]);
  };

  const handleRemoveClickFile = (id) => {
    setFilesRequired(filesRequired.filter((file) => file.id !== id));
  };

  const handleChangeDropdown = (event, id) => {
    const {
      target: { value },
    } = event;
    const updatedFiles = filesRequired.map((file) =>
      file.id === id
        ? {
            ...file,
            formats: typeof value === "string" ? value.split(",") : value,
          }
        : file
    );
    setFilesRequired(updatedFiles);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      toast.error("No File Selected");
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setUploading(true);
      setLoading(true);
      const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === 200) {
        setUploading(false);
        setLoading(false);
        setUploadedFile(response.data.data.filename);
        // toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const handleCreateEvent = async () => {
    try {
      if (
        !formData.eventName ||
        !formData.eventDetails ||
        !formData.eventEnd ||
        !formData.eventStart ||
        !formData.prizeMoney ||
        !formData.registrationFee ||
        !formData.selectedCategory ||
        !formData.sponsorDetails ||
        !uploadedFile
      ) {
        toast.error("Please fill all mandatory fields");
      } else {
        setLoading(true);
        const response = await axios.post(
          `${API_BASE_URL}/createEvent`,
          {
            // college_id: email,
            // host_ids: password,
            event_name: formData.eventName,
            event_location: formData.host,
            event_category: formData.selectedCategory,
            event_prize: formData.prizeMoney,
            registration_fees: formData.registrationFee,
            rule_book: formData.ruleBook,
            event_details: formData.eventDetails,
            event_image: uploadedFile,
            registration_link: formData.registrationLink,
            sponsor_details: formData.sponsorDetails,
            others: formData.otherDetails,
            event_start_date_time: formData.eventStart,
            event_end_date_time: formData.eventEnd,
            event_milestones: milestones,
            files_required: filesRequired,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (response.data.status === 200) {
          setLoading(false);
          navigate("/manage-events");
          // toast.success(response.data.message);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <div className={styles.eventCreatePage}>
      {isLoading && <Loader fullScreen />}
      <Container>
        <div className={styles.content}>
          <h2 className={styles.title}>
            Create Your <span>Perfect Event</span>
          </h2>
          <p className={styles.desc}>
            Craft Unforgettable Experiences with Our Easy-to-Use Event Creation
            Page
          </p>
          <form className={styles.createEventForm}>
            <div className={styles.columnsGroup}>
              <div className={styles.leftContent}>
                <div className={styles.formGroup}>
                  <label>Name of the event</label>
                  <input
                    type="text"
                    name="eventName"
                    value={formData.eventName}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Host College</label>
                  <input
                    type="text"
                    name="host"
                    value={formData.host}
                    onChange={handleChange}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label>Prize Money</label>
                  <input
                    type="text"
                    name="prizeMoney"
                    value={formData.prizeMoney}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={styles.rightContent}>
                <div className="">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="flex items-center justify-center w-80 h-72 border-2 border-dashed border-gray-400 rounded-lg">
                      {uploading ? (
                        <p className="text-gray-500 text-xl font-bold animate-pulse">
                          Uploading...
                        </p>
                      ) : preview ? (
                        <img
                          src={preview}
                          alt="Selected"
                          className="w-full h-full object-cover rounded-lg"
                        />
                      ) : (
                        <span className="text-gray-500">
                          Select Event Image up to 2 MB
                        </span>
                      )}
                    </div>
                    <div className="flex justify-between items-center gap-2">
                      <div>
                        <input
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="hidden"
                          id="image-upload"
                        />
                        <label htmlFor="image-upload">
                          <Button
                            component="span"
                            variant="contained"
                            color="primary"
                            className="px-4 py-2"
                            disabled={uploading}
                          >
                            Select Image
                          </Button>
                        </label>
                      </div>
                      <Button
                        onClick={handleFileUpload}
                        variant="contained"
                        color="success"
                        disabled={!file || uploading} // Disable the button if no file is selected
                        className="px-4 py-2"
                      >
                        Upload
                      </Button>
                      {!!uploadedFile ? (
                        <Tooltip title="Image uploaded" placement="top">
                          <CloudDoneIcon color="success" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Image not uploaded" placement="top">
                          <CloudOffIcon color="warning" />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.columnsGroup}>
              <div className={styles.formGroup}>
                <label>Registration Fee</label>
                <input
                  type="text"
                  name="registrationFee"
                  value={formData.registrationFee}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.formGroup}>
                <label>Registration Link (Optional)</label>
                <input
                  type="text"
                  name="registrationLink"
                  value={formData.registrationLink}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.columnsGroup}>
              <div className={styles.formGroup}>
                <label>Rule Book</label>
                <input
                  type="text"
                  name="ruleBook"
                  value={formData.ruleBook}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.formGroup}>
                <label>Sponsor Details</label>
                <input
                  type="text"
                  name="sponsorDetails"
                  value={formData.sponsorDetails}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={styles.columnsGroup}>
              <div className={styles.formGroup}>
                <label>Event Details</label>
                <textarea
                  name="eventDetails"
                  value={formData.eventDetails}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.formGroup}>
                <label>Other Details</label>
                <textarea
                  name="otherDetails"
                  value={formData.otherDetails}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-row w-[49%] gap-7">
              <div className="bg-white rounded-md w-full">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{ width: "100%" }}
                >
                  <DemoContainer
                    components={["DatePicker"]}
                    sx={{ width: "100%" }}
                  >
                    <DatePicker
                      label="Event starts on"
                      sx={{ width: "100%" }}
                      value={formData.eventStart}
                      onChange={handleStartDateChange}
                      format="DD/MM/YYYY"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="bg-white rounded-md w-full">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  sx={{ width: "100%" }}
                >
                  <DemoContainer
                    components={["DatePicker"]}
                    sx={{ width: "100%" }}
                  >
                    <DatePicker
                      label="Event ends on"
                      sx={{ width: "100%" }}
                      value={formData.eventEnd}
                      onChange={handleEndDateChange}
                      format="DD/MM/YYYY"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </form>
        </div>
        <div>
          <p className="font-semibold text-3xl mt-4">
            Choose The Event Category
          </p>
          <div className="bg-white w-1/3 p-2 rounded-sm mt-2">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.selectedCategory}
                name="selectedCategory"
                label="Select Category"
                onChange={handleChange}
              >
                {eventCategories.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <p className="font-semibold text-3xl mt-4">Create Milestones</p>
          <div className="bg-white p-2">
            {milestones.map((milestone, index) => (
              <div key={index} className="w-full rounded-lg mb-4">
                <div className="flex flex-row gap-2 items-center">
                  <div className="w-[30%] flex gap-x-2 items-center">
                    <div className="border border-gray-300 rounded-md w-2/4">
                      <p className="text-black p-3 my-1 font-medium">
                        Milestone {index + 1}
                      </p>
                    </div>
                    <div className="w-2/4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Date and time"
                          value={milestone.dateTime}
                          onChange={(newDate) =>
                            handleMilestoneChange(index, "dateTime", newDate)
                          }
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="w-[70%] flex justify-between items-center gap-x-2">
                    <div className="w-1/4">
                      <TextField
                        id={`outlined-basic-heading-${index}`}
                        label="Heading"
                        variant="outlined"
                        fullWidth
                        value={milestone.heading}
                        onChange={(e) =>
                          handleMilestoneChange(
                            index,
                            "heading",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="w-[55%]">
                      <TextField
                        id={`outlined-basic-description-${index}`}
                        label="Description"
                        variant="outlined"
                        fullWidth
                        value={milestone.description}
                        onChange={(e) =>
                          handleMilestoneChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="w-[17%]">
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Show to Participants"
                        sx={{ color: "black" }}
                      />
                    </div>
                    <div className="w-[3%] mr-2">
                      {milestones.length > 1 && (
                        <RemoveCircleIcon
                          className="ml-4"
                          fontSize="large"
                          color="error"
                          onClick={() => handleRemoveMilestone(milestone.id)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="mt-4 flex justify-start items-center">
              <div
                className="flex justify-center items-center border border-gray-300 rounded-md
                  w-[14.6%] cursor-pointer hover:bg-slate-200"
                onClick={handleAddMilestone}
              >
                <AddCircleIcon
                  className="ml-4"
                  fontSize="large"
                  color="success"
                />
                <p className="text-black p-3 my-1 text-base font-medium">
                  Add New Milestone
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className="font-semibold text-3xl mt-4">Files/Documents Required</p>
        <div className="bg-white p-2 mt-2 rounded-lg">
          {filesRequired.map((file, index) => (
            <div key={index}>
              <p className="text-lg text-black p-2 font-medium">
                File {index + 1}
              </p>
              <div className="w-full flex flex-row items-center">
                <div className="w-[23%]">
                  <TextField
                    id={`file-name-${index}`}
                    label="File Name"
                    variant="outlined"
                    fullWidth
                    value={file.fileName}
                    onChange={(e) =>
                      handleFileChange(index, "fileName", e.target.value)
                    }
                  />
                </div>
                <div>
                  <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id={`demo-multiple-checkbox-label-${index}`}>
                      Format Accepted
                    </InputLabel>
                    <Select
                      labelId={`demo-multiple-checkbox-label-${index}`}
                      id={`demo-multiple-checkbox-${index}`}
                      multiple
                      value={file.formats}
                      onChange={(event) => handleChangeDropdown(event, file.id)}
                      input={<OutlinedInput label="Format Accepted" />}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={file.formats.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="w-full">
                  <TextField
                    id={`file-description-${index}`}
                    label="File Description"
                    variant="outlined"
                    fullWidth
                    value={file.description}
                    onChange={(e) =>
                      handleFileChange(index, "description", e.target.value)
                    }
                  />
                </div>
                {/* <AddCircleIcon
                  className="ml-4"
                  fontSize="large"
                  color="success"
                  onClick={handleAddClickFile}
                  style={{ cursor: "pointer" }}
                /> */}
                {filesRequired.length > 1 && (
                  <RemoveCircleIcon
                    className="ml-4"
                    fontSize="large"
                    color="error"
                    onClick={() => handleRemoveClickFile(file.id)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            </div>
          ))}
          <div className="mt-4 flex justify-start items-center">
            <div
              className="flex justify-center items-center border border-gray-300 rounded-md
                  w-[14.6%] cursor-pointer hover:bg-slate-200"
              onClick={handleAddClickFile}
            >
              <AddCircleIcon
                className="ml-4"
                fontSize="large"
                color="success"
              />
              <p className="text-black p-3 my-1 text-base font-medium">
                Add New File
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4 justify-end flex">
          <Button
            variant="contained"
            size="large"
            onClick={handleCreateEvent}
            disabled={isLoading}
          >
            Create Event
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default EventCreate;
