import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Innerfooter } from "./Innerfooter";
import styles from "./Layout.module.scss";

const Layout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Header />
      {children}
      {/* <Footer /> */}
      <Innerfooter />
    </div>
  );
};

export default Layout; // Ensure Layout is exported as default