import React from "react";
import styles from "./Layout.module.scss";
import { Container } from "../Container";
import Facebook from "../../../images/akar-icons_facebook-fill.svg";
import Youtube from "../../../images/ion_logo-youtube.svg";
import Insta from "../../../images//streamline_instagram-solid.svg";

export const Innerfooter = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footer_content}>
          <div className={styles.top_cont}>
            <img src="/logo.png" alt="logo" width={133} height={100} />
            <div className={styles.social_icons}>
              <a href="#" target="_blank"><img src={Insta} alt="Insta" /></a>
              <a href="#" target="_blank"><img src={Youtube} alt="Twitter" /></a>
              <a href="#" target="_blank"><img src={Facebook} alt="Facebook" /></a>
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.copyright_cont}>
          <span>© Copyright 2024 Synclash - All rights reserved</span>
        </div>
      </Container>
    </footer>
  );
};
