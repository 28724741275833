"use client";
import React from "react";
import styles from "./EventDetailAfterParticipated.module.scss";
import { Container } from "../common/Container";
import { Chrono } from "react-chrono";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
// import img from "next/img";
// import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const items = [
  {
    title: "January 1, 2024",
    cardTitle: "Opening Ceremony",
    // cardSubtitle: "Welcome Event",
    cardDetailedText:
      "Kicking off the college fest with a grand opening ceremony.",
  },
  {
    title: "January 3, 2024",
    cardTitle: "Cultural Parade",
    // cardSubtitle: "Showcase of Diversity",
    cardDetailedText:
      "A vibrant parade showcasing the cultural diversity of the college.",
  },
  {
    title: "January 4, 2024",
    cardTitle: "Dance Competition",
    // cardSubtitle: "Dance Battle",
    cardDetailedText: "Students compete in a high-energy dance competition.",
  },
  {
    title: "January 6, 2024",
    cardTitle: "Debate Tournament",
    // cardSubtitle: "Clash of Minds",
    cardDetailedText: "A heated debate tournament on current issues.",
  },
  {
    title: "January 8, 2024",
    cardTitle: "Art Exhibition",
    // cardSubtitle: "Creative Showcase",
    cardDetailedText: "Displaying artwork created by talented students.",
  },
  {
    title: "January 10, 2024",
    cardTitle: "Music Concert",
    // cardSubtitle: "Live Performances",
    cardDetailedText: "Enjoying live music performances by student bands.",
  },
  {
    title: "January 11, 2024",
    cardTitle: "Science Fair",
    // cardSubtitle: "Innovative Projects",
    cardDetailedText: "Presenting innovative science projects and experiments.",
  },
  {
    title: "January 13, 2024",
    cardTitle: "Drama Night",
    // cardSubtitle: "Theater Performances",
    cardDetailedText: "An evening of theater performances by the drama club.",
  },
  {
    title: "January 15, 2024",
    cardTitle: "Sports Day",
    // cardSubtitle: "Athletic Events",
    cardDetailedText: "A day filled with various sports and athletic events.",
  },
  {
    title: "January 17, 2024",
    cardTitle: "Fashion Show",
    // cardSubtitle: "Style and Glamour",
    cardDetailedText: "Students showcase their fashion designs on the runway.",
  },
  {
    title: "January 18, 2024",
    cardTitle: "Photography Contest",
    // cardSubtitle: "Capturing Moments",
    cardDetailedText:
      "A contest for the best photographs capturing fest moments.",
  },
  {
    title: "January 20, 2024",
    cardTitle: "Cooking Competition",
    // cardSubtitle: "Culinary Skills",
    cardDetailedText: "Showcasing culinary skills in a cooking competition.",
  },
  {
    title: "January 21, 2024",
    cardTitle: "Tech Talk",
    // cardSubtitle: "Innovative Ideas",
    cardDetailedText: "A session on innovative technology ideas and trends.",
  },
  {
    title: "January 23, 2024",
    cardTitle: "Treasure Hunt",
    // cardSubtitle: "Fun Adventure",
    cardDetailedText: "An adventurous treasure hunt around the campus.",
  },
  {
    title: "January 24, 2024",
    cardTitle: "Quiz Competition",
    // cardSubtitle: "Test Your Knowledge",
    cardDetailedText:
      "A quiz competition to test students' knowledge on various topics.",
  },
  {
    title: "January 25, 2024",
    cardTitle: "Closing Ceremony",
    // cardSubtitle: "Farewell Event",
    cardDetailedText:
      "Ending the college fest with a memorable closing ceremony.",
  },
];

const EventDetailAfterParticipated = () => {
  return (
    <div className={styles.eventsDetailPage}>
      <Container>
        <div className={styles.content}>
          <div className={styles.pageContent}>
            <div className={styles.detailContent}>
              <div className={styles.mainInfo}>
                <img src="/Carousel.png" alt="logo" width={100} height={100} />
                <div className={styles.eventTopInfo}>
                  <h2>Cultural Festivals</h2>
                  <p>Mood Indigo by IIT Bombay</p>
                  <div className={styles.eventInfo}>
                    <p>
                      <img src="/users.svg" alt="logo" width={22} height={22} />
                      <span className={styles.registered}>244 registered</span>
                    </p>
                    <p>
                      <img
                        src="/troffy.svg"
                        alt="logo"
                        width={22}
                        height={22}
                      />
                      <span className={styles.competitions}>
                        3 Competition(s)
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.descriptionInfo}>
                <h2>Let&apos;s see, what Optical Invasion is all about?</h2>
                <p>
                  {" "}
                  containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum. Why do we use it?
                </p>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using Content
                  here, content here, making it look like readable English. Many
                  desktop publishing packages and web page editors now use Lorem
                  Ipsum as their default model text, and a search for lorem
                  ipsum will uncover many web sites still in their infancy.
                  Various versions have evolved over the years, sometimes by
                  accident, sometimes on purpose (injected humour and the like).
                </p>
                <ul>
                  <li>lorem ispum dolloer</li>
                  <li>lorem ispum dolloer</li>
                  <li>lorem ispum dolloer</li>
                </ul>
              </div>
              <div className={styles.opportunitiesInfo}>
                <h2 className="mt-6">
                  <span>Your Participation Status: In-Review / Approved</span>
                </h2>
                <div className="flex items-center justify-center">
                  <TableContainer component={Paper} sx={{ maxWidth:1000 }}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Submitted File Name
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Status
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Select
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {/* <div className="bg-yellow-600 rounded-sm justify-center flex">
                              <p className="p-1 font-semibold text-white">In-Review</p>
                              </div> */}
                              {/* <div className="bg-red-600 rounded-sm justify-center flex">
                              <p className="p-1 font-semibold text-white">Rejected</p>
                              </div> */}
                              <div className="bg-green-600 rounded-sm justify-center flex">
                              <p className="p-1 font-semibold text-white">Appoved</p>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                            <input type="file" />
                            {/* No action required */}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                            <Button variant="contained" disabled>Re-submit</Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <div className={styles.opportunitiesInfo}>
                <h2 className="mt-6">
                  <span>Timeline View</span>
                </h2>
                <div>
                  <Chrono
                    items={items}
                    mode="HORIZONTAL"
                    itemWidth={150}
                    activeItemIndex={6}
                    // showSingle
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EventDetailAfterParticipated;
