import React, { useEffect, useState } from "react";
import { Container } from "../common/Container";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import API_BASE_URL from "../../Utils/Utils";
import Loader from "../Loader";

import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PublishIcon from "@mui/icons-material/Publish";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function MyEvents() {
  const [isLoading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [applications, setApplications] = useState([]);
  const [files, setFiles] = useState([]);
  const [openedApplicationId, setOpenedApplicationId] = useState();
  const [selectedFiles, setSelectedFiles] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.alert("Please login to access this page.");
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const userRoleFromStorage = localStorage.getItem("user_is");
    setUserRole(userRoleFromStorage);
  }, []);

  useEffect(() => {
    if (userRole === "participant") {
      getMyApplications();
    }
  }, [userRole]);

  const getMyApplications = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/getAppliedList`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        setLoading(false);
        setApplications(response.data.data);
        // toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const getApplicationById = async (aId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/getApplicationById`, {
        params: {
          application_id: aId,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        setLoading(false);
        setOpenedApplicationId(response.data.data.application_id);
        setFiles(response.data.data.files_submitted);
        // toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching application data:", error);
      setLoading(false);
      toast.error(error);
    }
  };

  const handleFileChange = (e, fileId) => {
    const newSelectedFiles = { ...selectedFiles, [fileId]: e.target.files[0] };
    setSelectedFiles(newSelectedFiles);
  };

  const handleUpload = async (fileId) => {
    const fileToUpload = selectedFiles[fileId];
    if (!fileToUpload) return;

    const formData = new FormData();
    formData.append("file", fileToUpload);

    try {
      setLoading(true);
      const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const newFiles = files.map((file) =>
          file.id === fileId
            ? {
                ...file,
                fileLink: response.data.data.filename,
                fileStatus: "in_review",
              }
            : file
        );
        setFiles(newFiles);
        // toast.success("File uploaded successfully");

        // Call the resubmit API with updated files and application_id
        const resubmitResponse = await axios.post(
          `${API_BASE_URL}/resubmitFiles`,
          {
            application_id: openedApplicationId,
            files_submitted: newFiles,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (resubmitResponse.status === 200) {
          toast.success("Files resubmitted successfully");
          getApplicationById(openedApplicationId);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Failed to resubmit files");
        }
      } else {
        toast.error("File upload failed");
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <Container>
      {isLoading && <Loader fullScreen />}
      {applications.length > 0 && (
        <div className="flex w-full min-h-[52vh]">
          <div className="max-h-96 w-[35%] rounded-lg overflow-hidden">
            <p className="font-normal text-xl p-4">
              All the events you applied for. Click for details.
            </p>
            <TableContainer
              component={Paper}
              className="overflow-y-auto max-h-96"
            >
              <Table sx={{}} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Event Name</StyledTableCell>
                    <StyledTableCell align="left">College</StyledTableCell>
                    <StyledTableCell align="left">Start Date</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applications.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      className="cursor-pointer"
                      onClick={() => getApplicationById(row.application_id)}
                    >
                      <StyledTableCell align="left" component="th" scope="row">
                        {row.event_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.college}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(row.event_start_date_time).format(
                          "DD MMM - YYYY"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.application_status}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="overflow-x-auto rounded-lg ml-4">
            <p className="font-normal text-xl p-4">
              Re-submit the file if status is rejected.
            </p>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    File Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Upload File
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Format
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Resubmit Reason
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {files?.length === 0 && (
                  <tr>
                    <td
                      colSpan="6"
                      className="text-center py-4 text-2xl font-semibold text-slate-700"
                    >
                      No Data
                    </td>
                  </tr>
                )}
                {files?.map((item) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {item.fileName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <input
                        className="block w-full text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer
                              bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700
                              dark:border-gray-600 dark:placeholder-gray-400"
                        id={`file_input_${item.id}`}
                        type="file"
                        disabled={item.fileStatus !== "re_submit"}
                        onChange={(e) => handleFileChange(e, item.id)}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.formats.join(", ")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Tooltip
                        title={item.reason || "NA"}
                        placement="top-start"
                      >
                        <Button>Hover</Button>
                      </Tooltip>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Button
                        variant="contained"
                        disabled={
                          item.fileStatus !== "re_submit" ||
                          !selectedFiles[item.id]
                        }
                        onClick={() => handleUpload(item.id)}
                      >
                        Re-submit
                      </Button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.fileStatus === "re_submit" && (
                        <Tooltip title="Re-submit" placement="top-start">
                          <PublishIcon className="text-red-500" />
                        </Tooltip>
                      )}
                      {item.fileStatus === "approved" && (
                        <Tooltip title="Approved" placement="top-start">
                          <CheckBoxIcon className="text-green-700" />
                        </Tooltip>
                      )}
                      {item.fileStatus === "in_review" && (
                        <Tooltip title="In Review" placement="top-start">
                          <ImageSearchIcon className="text-orange-700" />
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {applications.length === 0 && (
        <div className="min-h-[52vh]">
          <p className="flex justify-center items-center font-medium text-4xl text-slate-400 mt-10">
            No applications yet
          </p>
        </div>
      )}
      <ToastContainer />
    </Container>
  );
}

export default MyEvents;
