import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Homepage.module.scss";
import Banner from "./Banner";
import { PopularEvents } from "./PopularEvents";
import { WhySync } from "./WhySync";

const Homepage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userIs = localStorage.getItem("user_is");
    if (userIs === "host") {
      navigate("/manage-events");
    }
  }, [navigate]);

  return (
    <div className={styles.homepage}>
      <Banner />
      <PopularEvents />
      <WhySync />
    </div>
  );
};

export default Homepage;
