import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Layout from './Components/common/Layout/Layout';
import Homepage from './Components/HomePage/Homepage';
import Event from './Components/Event/Event';
import EventCreate from './Components/EventCreate/EventCreate';
import EventDetails from './Components/EventDetail/EventDetail';
import EventParticipation from './Components/EventParticipation/EventParticipation';
import ManageEvents from './Components/ManageEvents/ManageEvents';
import EventDetailAfterParticipated from './Components/EventDetailAfterParticipated/EventDetailAfterParticipated';
import ViewParticipants from './Components/ViewParticipants/ViewParticipants';
import EventDetailForHost from './Components/EventDetailForHost/EventDetailForHost';
import MyEvents from './Components/MyEvents/MyEvents';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Homepage /></Layout>} />
        <Route path="/events" element={<Layout><Event /></Layout>} />
        <Route path="/create-event" element={<Layout><EventCreate /></Layout>} />
        <Route path="/event-details" element={<Layout><EventDetails /></Layout>} />
        <Route path="/participation/:id" element={<Layout><EventParticipation /></Layout>} />
        <Route path="/manage-events" element={<Layout><ManageEvents /></Layout>} />
        <Route path="/my-event" element={<Layout><EventDetailAfterParticipated /></Layout>} />
        <Route path="/view-participants/:id" element={<Layout><ViewParticipants /></Layout>} />
        <Route path="/manage-event/:id" element={<Layout><EventDetailForHost /></Layout>} />
        <Route path="/my-events" element={<Layout><MyEvents /></Layout>} />
        {/* <Route path="/desktop" element={<Desktop />} /> */}
      </Routes>
    </Router>
  );
}
