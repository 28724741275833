import React, { useState, useEffect } from "react";
import styles from "./Layout.module.scss";
import { Button } from "../Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
// import { useRouter } from 'next/navigation';
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../../Utils/Utils";
import Loader from "../../Loader";

import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [signupParticipantModal, setSignupParticipantModal] = useState(false);
  const [openForHostLogin, setOpenForHostLogin] = useState(false);
  const [openVerifyOtp, setOpenVerifyOtp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [signupParticipant, setSignupParticipant] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    collegeName: "",
  });
  const [OTPInput, setOTPInput] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setIsUserLoggedIn(localStorage.getItem("user_is"));
  }, []);

  const handleClose = () => setOpen(false);
  const handleCloseHostLogin = () => setOpenForHostLogin(false);
  const handleCloseSignupParticipant = () => setSignupParticipantModal(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpenSignupParticipant = () => {
    handleClose();
    setSignupParticipantModal(true);
  };

  const handleOpen = () => {
    setSignupParticipantModal(false);
    setOpen(true);
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/participantLogin`, {
        participant_email: email,
        participant_password: password,
      });

      if (response.data.status === 200) {
        const { token, user_is } = response.data.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user_is", user_is);
        setLoading(false);
        setIsUserLoggedIn(user_is);
        handleClose();
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Login failed!");
      setLoading(false);
    }
  };

  const handleHostLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/hostLogin`, {
        host_email: email,
        host_password: password,
      });

      if (response.data.status === 200) {
        const { token, user_is } = response.data.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user_is", user_is);
        setLoading(false);
        setIsUserLoggedIn(user_is);
        handleCloseHostLogin();
        navigate("/manage-events");
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Login failed!");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_is");
    setIsUserLoggedIn(null);
    navigate("/");
  };

  const renderButton = (label, onClickHandler) => (
    <div onClick={onClickHandler}>
      <Button label={label} />
    </div>
  );

  //--participant--

  const handleNewParticipantInput = (e) => {
    const { id, value } = e.target;
    setSignupParticipant((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleParticipantSignUp = async () => {
    setLoading(true);
    try {
      if (
        !signupParticipant.name ||
        !signupParticipant.collegeName ||
        !signupParticipant.email ||
        !signupParticipant.password ||
        !signupParticipant.phoneNumber
      ) {
        toast.error("Please input all fields");
      } else if (!validatePassword(signupParticipant.password)) {
        console.log("Password validation failed");
      } else {
        const response = await axios.post(
          `${API_BASE_URL}/signUpAsParticipant`,
          {
            participant_name: signupParticipant?.name,
            participant_email: signupParticipant?.email,
            participant_phone: signupParticipant?.phoneNumber,
            participant_password: signupParticipant?.password,
            participant_college: signupParticipant?.collegeName,
          }
        );
        if (response.data.status === 200) {
          toast.success(response.data.message);
          setSignupParticipantModal(false);
          setOpen(false);
          setOpenVerifyOtp(true);
          setLoading(false);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      }
    } catch (error) {
      toast.error("Login failed!");
      setLoading(false);
    }
  };

  const validatePassword = (password) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    if (!passwordPattern.test(password)) {
      toast.error(
        "At least 8 characters, with one upper& one lowercase letter, and one special character is required."
      );
      return false;
    }
    return true;
  };

  const handleOTPChange = (e) => {
    const input = e.target;
    const value = input.value.slice(0, 4);
    setOTPInput(value);
  };

  const handleVerifyOTPAPI = async () => {
    setLoading(true);
    try {
      if (!OTPInput) {
        toast.error("Enter 4 digit OTP");
      } else {
        const response = await axios.post(`${API_BASE_URL}/verifyOtp`, {
          participant_email: signupParticipant.email,
          otp: OTPInput,
        });

        if (response.data.status === 200) {
          const { token, user_is } = response.data.data;
          localStorage.setItem("token", token);
          localStorage.setItem("user_is", user_is);
          setIsUserLoggedIn(user_is);
          setOpenVerifyOtp(false);
          toast.success(response.data.message);
          setLoading(false);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      }
    } catch (error) {
      toast.error("Login failed!");
      setLoading(false);
    }
  };

  return (
    <div className={styles.header}>
      {isLoading && <Loader fullScreen />}
      <div className={styles.header_cont}>
        <img
          src="/logo.png"
          alt="logo"
          width={133}
          height={100}
          onClick={() => navigate("/")}
        />
        <div className={styles.search_cont}>
          <img src="/search.svg" alt="search" width={15} height={15} />
          <input type="text" placeholder="Search" />
        </div>
      </div>
      <div className={styles.login_cont}>
        <>
          {!isUserLoggedIn && (
            <>
              {renderButton("Login To Participate", handleOpen)}
              {renderButton("Login To Host", () => setOpenForHostLogin(true))}
            </>
          )}
          {isUserLoggedIn && (
            <>
              {renderButton(
                isUserLoggedIn === "participant"
                  ? "My Events"
                  : "Manage Events",
                () =>
                  navigate(
                    isUserLoggedIn === "participant"
                      ? "/my-events"
                      : "/manage-events"
                  )
              )}
              {renderButton("Logout", handleLogout)}
            </>
          )}
        </>
      </div>
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="font-medium text-2xl justify-center items-center flex mb-2">
            Login As Participant
          </p>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="mt-4">
            <FormControl sx={{}} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
          <div
            className="mt-4 flex items-center justify-center"
            onClick={handleLogin}
          >
            <Button label="Login" />
          </div>
          <div className="flex justify-center mt-4">
            <p>Don't have an account?</p>
            <p
              className="ml-2 text-blue-800 font-bold cursor-pointer"
              onClick={handleOpenSignupParticipant}
            >
              Sign Up
            </p>
          </div>
        </Box>
      </Modal>
      <Modal
        open={signupParticipantModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="relative w-full">
            <div className="flex justify-center">
              <p className="font-medium text-2xl flex items-center mb-2">
                Sign-up As Participant
              </p>
            </div>
            <div
              className="absolute top-0 right-0 font-bold text-red-600 cursor-pointer"
              onClick={handleCloseSignupParticipant}
            >
              <CloseIcon />
            </div>
          </div>

          <div className="mb-3">
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              fullWidth
              value={signupParticipant.name}
              onChange={handleNewParticipantInput}
              required
            />
          </div>
          <div className="mb-3">
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              value={signupParticipant.email}
              onChange={handleNewParticipantInput}
              required
            />
          </div>
          <div className="mb-3">
            <TextField
              id="phoneNumber"
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={signupParticipant.phoneNumber}
              onChange={handleNewParticipantInput}
              required
            />
          </div>
          <div className="mb-3">
            <TextField
              id="password"
              label="Create Password"
              variant="outlined"
              fullWidth
              value={signupParticipant.password}
              onChange={handleNewParticipantInput}
              required
              inputProps={{
                pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}",
              }}
              helperText="At least 8 characters, with one upper & lowercase and one special character is required."
            />
          </div>
          <div className="mb-3">
            <TextField
              id="collegeName"
              label="College Name"
              variant="outlined"
              fullWidth
              value={signupParticipant.collegeName}
              onChange={handleNewParticipantInput}
              required
            />
          </div>
          <div
            className="mt-4 flex items-center justify-center"
            onClick={handleParticipantSignUp}
          >
            <Button label="Get OTP To Verify" />
          </div>
          <div className="flex justify-center mt-4">
            <p>Already have an account?</p>
            <p
              className="ml-2 text-blue-800 font-bold cursor-pointer"
              onClick={handleOpen}
            >
              Login
            </p>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openVerifyOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="font-medium text-2xl justify-center items-center flex mb-2">
            Verify Your Email
          </p>
          <TextField
            id="outlined-basic"
            label="Enter OTP"
            type="number"
            variant="outlined"
            inputProps={{ max: 9999, inputMode: "numeric" }}
            onInput={handleOTPChange}
            value={OTPInput}
            required
            fullWidth
          />
          <div
            className="mt-4 flex items-center justify-center"
            onClick={handleVerifyOTPAPI}
          >
            <Button label="Verify OTP and Login" />
          </div>
        </Box>
      </Modal>
      <Modal
        open={openForHostLogin}
        onClose={handleCloseHostLogin}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="font-medium text-2xl justify-center items-center flex mb-2">
            Login As Host
          </p>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="mt-4">
            <FormControl sx={{}} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </div>
          <div
            className="mt-4 flex items-center justify-center"
            onClick={handleHostLogin}
          >
            <Button label="Login" />
          </div>
          <div className="flex justify-center items-center text-center mt-4">
            <p>For any assistance please email at admin@synclash.com</p>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
