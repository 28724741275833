import React, { useState, useCallback, useEffect } from "react";
import styles from "./Event.module.scss";
import { Container } from "../common/Container";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../Utils/Utils";
import Loader from "../Loader";

const Event = () => {
  const navigate = useNavigate();
  const buttons = [
    { label: "All", id: "all", value: "" },
    {
      label: "Today",
      id: "today",
      value: {
        from: moment().startOf("day").format(),
        to: moment().endOf("day").format(),
      },
    },
    {
      label: "This Month",
      id: "month",
      value: {
        from: moment().startOf("month").format(),
        to: moment().endOf("month").format(),
      },
    },
  ];

  const [selectedButton, setSelectedButton] = useState(buttons[0]);
  const [selectedCategoryButton, setSelectedCategoryButton] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const commonClassName =
    "focus:outline-none font-medium rounded-full text-base px-5 py-2.5 me-2 mb-2";
  const defaultClassName =
    "text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700";
  const activeClassName =
    "text-white bg-[#00B7FF] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 dark:focus:ring-blue-800";
  const categoryActiveClassName =
    "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 dark:focus:ring-blue-800";

  const categoryButtons = [
    { label: "Dance", id: "Dance" },
    { label: "Art", id: "Art" },
    { label: "Music", id: "Music" },
  ];

  const handleButtonClick = (id, value) => {
    setSelectedButton({ id, value });
  };

  const handleCategoryButtonClick = (id) => {
    if (selectedCategoryButton === id) {
      setSelectedCategoryButton(null);
    } else {
      setSelectedCategoryButton(id);
    }
  };

  //api call-------------

  const getEvents = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/getEventsByFilters`, {
        params: {
          from: selectedButton?.value?.from || "",
          to: selectedButton?.value?.to || "",
          category: selectedCategoryButton || "",
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        setLoading(false);
        setEvents(response.data.data);
        // toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching event details:", error);
      toast.error("Error fetching event details");
    }
  });

  useEffect(() => {
    getEvents();
  }, [selectedButton, selectedCategoryButton]);

  return (
    <div className={styles.eventsPage}>
      {isLoading && <Loader fullScreen />}
      <Container>
        <div className={styles.content}>
          <h2 className={styles.title}>
            All <span>Events</span>
          </h2>
          <div className={styles.filter_list}>
            {buttons?.map((button) => (
              <button
                key={button.id}
                type="button"
                className={`${commonClassName} ${
                  selectedButton && selectedButton.id === button.id
                    ? activeClassName
                    : defaultClassName
                }`}
                onClick={() => handleButtonClick(button.id, button.value)}
              >
                {button.label}
              </button>
            ))}
            <hr />
            {categoryButtons?.map((button) => (
              <button
                key={button.id}
                type="button"
                className={`${commonClassName} ${
                  selectedCategoryButton === button.id
                    ? categoryActiveClassName
                    : defaultClassName
                }`}
                onClick={() => handleCategoryButtonClick(button.id)}
              >
                {button.label}
              </button>
            ))}
          </div>
          {events.length === 0 && (
            <p className="justify-center flex items-center font-medium text-3xl text-gray-400">
              No Events Found
            </p>
          )}
          <div className="grid grid-cols-3 gap-4">
            {events.map((value, index) => {
              return (
                <div
                  className={styles.eventsCards}
                  onClick={() =>
                    window.open(`/participation/${value?.event_id}`, "_blank")
                  }
                  id={value.event_id}
                >
                  <div className={styles.eventCard}>
                    <img
                      src={value.event_image}
                      alt="logo"
                      width={100}
                      height={100}
                    />
                    <div className={styles.eventCardContent}>
                      <div className={styles.eventTopInfo}>
                        <p className="text-xl">
                          {value.event_name} by {value.college}
                        </p>
                        <h2>{value.event_category}</h2>
                        <div className={styles.eventInfo}>
                          <p>
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.16797 6.33677C1.16797 4.38775 1.16797 3.41299 1.77371 2.80777C2.37893 2.20203 3.3537 2.20203 5.30271 2.20203H7.37008C9.31909 2.20203 10.2939 2.20203 10.8991 2.80777C11.5048 3.41299 11.5048 4.38775 11.5048 6.33677V7.37045C11.5048 9.31946 11.5048 10.2942 10.8991 10.8995C10.2939 11.5052 9.31909 11.5052 7.37008 11.5052H5.30271C3.3537 11.5052 2.37893 11.5052 1.77371 10.8995C1.16797 10.2942 1.16797 9.31946 1.16797 7.37045V6.33677Z"
                                stroke="url(#paint0_linear_112_1270)"
                                stroke-width="1.03368"
                              ></path>
                              <path
                                d="M3.75293 2.20199V1.42673M8.92135 2.20199V1.42673"
                                stroke="url(#paint1_linear_112_1270)"
                                stroke-width="1.03368"
                                stroke-linecap="round"
                              ></path>
                              <path
                                d="M5.04429 8.11273L1.16797 5.977L11.5048 5.977"
                                stroke="url(#paint2_linear_112_1270)"
                                stroke-width="1.03368"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_112_1270"
                                  x1="9.92389"
                                  y1="12.6216"
                                  x2="1.85612"
                                  y2="10.9456"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    offset="0.025"
                                    stop-color="#FFC0CB"
                                  ></stop>
                                  <stop
                                    offset="0.421667"
                                    stop-color="#E0B0FF"
                                  ></stop>
                                  <stop offset="1" stop-color="#89CFF0"></stop>
                                </linearGradient>
                                <linearGradient
                                  id="paint1_linear_112_1270"
                                  x1="8.13089"
                                  y1="2.29502"
                                  x2="6.48304"
                                  y2="0.241077"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    offset="0.025"
                                    stop-color="#FFC0CB"
                                  ></stop>
                                  <stop
                                    offset="0.421667"
                                    stop-color="#E0B0FF"
                                  ></stop>
                                  <stop offset="1" stop-color="#89CFF0"></stop>
                                </linearGradient>
                                <linearGradient
                                  id="paint2_linear_112_1270"
                                  x1="12.7452"
                                  y1="6.30364"
                                  x2="12.6782"
                                  y2="8.0399"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    offset="0.025"
                                    stop-color="#FFC0CB"
                                  ></stop>
                                  <stop
                                    offset="0.421667"
                                    stop-color="#E0B0FF"
                                  ></stop>
                                  <stop offset="1" stop-color="#89CFF0"></stop>
                                </linearGradient>
                              </defs>
                            </svg>
                            <span className={styles.eventdate}>
                              {moment(value.event_start_date_time).format(
                                "MMM D"
                              )}{" "}
                              -{" "}
                              {moment(value.event_end_date_time).format(
                                "MMM D"
                              )}
                            </span>
                          </p>
                          <p>
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_112_1278)">
                                <path
                                  d="M6.27242 0.348602C9.57973 0.348602 12.2607 3.02954 12.2607 6.33685C12.2607 9.64415 9.57973 12.3251 6.27242 12.3251C2.96512 12.3251 0.28418 9.64415 0.28418 6.33685C0.28418 3.02954 2.96512 0.348602 6.27242 0.348602ZM6.27242 1.18396C4.90579 1.18396 3.59514 1.72685 2.62878 2.69321C1.66243 3.65956 1.11954 4.97022 1.11954 6.33685C1.11954 7.70348 1.66243 9.01413 2.62878 9.98048C3.59514 10.9468 4.90579 11.4897 6.27242 11.4897C7.63905 11.4897 8.94971 10.9468 9.91606 9.98048C10.8824 9.01413 11.4253 7.70348 11.4253 6.33685C11.4253 4.97022 10.8824 3.65956 9.91606 2.69321C8.94971 1.72685 7.63905 1.18396 6.27242 1.18396ZM5.71552 3.69064C5.94606 3.69064 6.1329 3.87807 6.1329 4.10862V7.03328H9.05756C9.16559 7.03737 9.26785 7.08317 9.34283 7.16105C9.41782 7.23893 9.45972 7.34284 9.45972 7.45096C9.45972 7.55908 9.41782 7.66299 9.34283 7.74087C9.26785 7.81875 9.16559 7.86455 9.05756 7.86864H5.71552C5.60466 7.86864 5.49835 7.8246 5.41996 7.74622C5.34157 7.66783 5.29754 7.56151 5.29754 7.45066V4.10922C5.29754 3.87807 5.48437 3.69064 5.71552 3.69064Z"
                                  fill="url(#paint0_linear_112_1278)"
                                ></path>
                              </g>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_112_1278"
                                  x1="10.429"
                                  y1="13.7623"
                                  x2="1.00743"
                                  y2="12.0008"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop
                                    offset="0.025"
                                    stop-color="#FFC0CB"
                                  ></stop>
                                  <stop
                                    offset="0.421667"
                                    stop-color="#E0B0FF"
                                  ></stop>
                                  <stop offset="1" stop-color="#89CFF0"></stop>
                                </linearGradient>
                                <clipPath id="clip0_112_1278">
                                  <rect
                                    width="11.9765"
                                    height="11.9765"
                                    fill="white"
                                    transform="translate(0.28418 0.348602)"
                                  ></rect>
                                </clipPath>
                              </defs>
                            </svg>
                            <span className={styles.eventTissme}>
                              {moment(value.event_start_date_time).format(
                                "h:mm A"
                              )}{" "}
                              -{" "}
                              {moment(value.event_end_date_time).format(
                                "h:mm A"
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className={styles.eventBottomInfo}>
                        <p className="bg-amber-700 p-2 rounded-full font-semibold text-lg mr-2">
                          {moment().isBefore(
                            moment(value.event_start_date_time)
                          )
                            ? "Upcoming"
                            : moment().isAfter(
                                moment(value.event_end_date_time)
                              )
                            ? "Finished"
                            : "On Going"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Event;
