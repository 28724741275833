import React, { useState, useEffect } from "react";
import styles from "./EventParticipation.module.scss";
import { Container } from "../common/Container";
import { Chrono } from "react-chrono";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useParams } from "react-router-dom";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import API_BASE_URL from "../../Utils/Utils";
import Loader from "../Loader";
import "react-toastify/dist/ReactToastify.css";
const moment = require("moment");

const EventParticipation = () => {
  const [participantData, setParticipantData] = useState({
    collegeId: "",
    degree: "",
    age: "",
    semester: "",
  });
  const [fileState, setFileState] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [event, setEvent] = useState({});
  const { id } = useParams();
  const [rectifiedItems, setRectifiedItems] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.alert("Please login to access this page.");
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setParticipantData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  //---api call-----

  const handleFileChange = (e, id) => {
    setFileState({
      ...fileState,
      [id]: {
        file: e.target.files[0],
      },
    });
  };

  const handleUpload = async (id) => {
    const formData = new FormData();
    formData.append("file", fileState[id].file);
    setLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setLoading(false);
        const uploadedFileData = response.data.data.filename;
        const item = event.files_required.find((item) => item.id === id);

        setUploadedFiles((prevUploadedFiles) => {
          const existingIndex = prevUploadedFiles.findIndex(
            (file) => file.id === id
          );
          const updatedFiles = [...prevUploadedFiles];

          const newFileData = {
            id: item.id,
            fileName: item.fileName,
            formats: item.formats,
            description: item.description,
            fileLink: uploadedFileData,
            fileStatus: "in_review",
          };

          if (existingIndex > -1) {
            updatedFiles[existingIndex] = newFileData;
          } else {
            updatedFiles.push(newFileData);
          }

          return updatedFiles;
        });
        toast.success(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      console.error("Error uploading file:", error);
    }
  };

  const getEvent = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/getEventById`, {
        params: {
          event_id: id,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      if (response.status === 200) {
        setLoading(false);
        setEvent(response.data.data);
        // toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const handleApplyEvent = async () => {
    try {
      if (
        !participantData.collegeId ||
        !participantData.age ||
        !participantData.degree ||
        !participantData.semester ||
        uploadedFiles.length !== event?.files_required.length
      ) {
        toast.error("Please fill all mandatory fields");
      } else {
        setLoading(true);
        const response = await axios.post(
          `${API_BASE_URL}/sendApplication`,
          {
            id_number: participantData.collegeId,
            degree: participantData.degree,
            age: participantData.age,
            semester: participantData.semester,
            event_id: id,
            files_submitted: uploadedFiles,
          },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (response.data.status === 200) {
          setLoading(false);
          navigate("/events");
          // toast.success(response.data.message);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    const transformedItems = event?.event_milestones?.map((item) => ({
      title: moment(item.dateTime).format("D MMM, HH:mm") + " hrs",
      cardTitle: item.heading,
      cardDetailedText: item.description,
      dateTime: item.dateTime,
    }));

    setRectifiedItems(transformedItems);

    if (transformedItems) {
      const today = moment();
      let closestPastDateIndex = null;
      let closestFutureDateIndex = null;

      transformedItems.forEach((item, index) => {
        const itemDate = moment(item.dateTime);
        if (itemDate.isSame(today, "day")) {
          closestPastDateIndex = index;
        }
        if (itemDate.isBefore(today)) {
          if (
            closestPastDateIndex === null ||
            itemDate.isAfter(
              moment(transformedItems[closestPastDateIndex].dateTime)
            )
          ) {
            closestPastDateIndex = index;
          }
        }
        if (itemDate.isAfter(today)) {
          if (
            closestFutureDateIndex === null ||
            itemDate.isBefore(
              moment(transformedItems[closestFutureDateIndex].dateTime)
            )
          ) {
            closestFutureDateIndex = index;
          }
        }
      });
      const highlightIndex =
        closestPastDateIndex !== null
          ? closestPastDateIndex
          : closestFutureDateIndex;
      setHighlightedIndex(highlightIndex !== null ? highlightIndex : 0);
    }
  }, [event]);

  return (
    <div className={styles.eventParticipationPage}>
      {isLoading && <Loader fullScreen />}
      <div className={styles.eventParticipationBanner}>
        <img src="/participation-banner.jpg" alt="Event Participation Banner" />
        <Container>
          <div className={styles.content}>
            <h2 className={styles.title}>
              Participate in{" "}
              <span>
                {event?.event_name} <br />
                {event?.college}
              </span>
            </h2>
            <p className={styles.desc}>
              Craft Unforgettable Experiences with Our Easy-to-Use Event
              Creation Page
            </p>
            <div className={styles.eventDetails}>
              <p>
                <span>Start Date</span>{" "}
                {moment(event.event_start_date_time).format("MMM D, HH:mm")}
              </p>
              <p>
                <span>End Day</span>{" "}
                {moment(event.event_end_date_time).format("MMM D, HH:mm")}
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.eventParticipationForm}>
        <Container>
          <div className={styles.detailContent}>
            <div className={styles.descriptionInfo}>
              <h2>Let&apos;s see, what {event.event_name} is all about.</h2>
              <p> {event.event_details}</p>
              <p>{event.others}</p>
              <p>Event Location: {event.event_location || "NA"}</p>
              <p>Event Prize: {event.event_prize || "NA"}</p>
              <p>Registration Fee: {event.registration_fees || "NA"}</p>
              <p>Registration Link: {event.registration_link || "NA"}</p>
              <p>Rule Book: {event.rule_book || "NA"}</p>
              <p>Sponsor Details: {event.sponsor_details || "NA"}</p>
              <p>Files Requested:</p>
              {event?.files_required?.map((value, index) => {
                return (
                  <ul key={index}>
                    <li>
                      {value.fileName || "NA"}: {value.formats || "NA"}
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>

          <h2 className="mt-6">
            <span>Timeline View</span>
          </h2>
          <div>
            {!!rectifiedItems && (
              <Chrono
                items={rectifiedItems}
                mode="HORIZONTAL"
                itemWidth={150}
                activeItemIndex={highlightedIndex}
                // showSingle
              />
            )}
          </div>
          <h2>
            <span>Let's get you prepared for the event.</span>
          </h2>
          <div className="bg-white rounded-lg p-4 mb-4">
            <div className="flex flex-row gap-10">
              <TextField
                id="collegeId"
                label="College ID/Roll Number"
                variant="outlined"
                fullWidth
                value={participantData.collegeId}
                onChange={handleChange}
              />
              <TextField
                id="degree"
                label="Degree/Course"
                variant="outlined"
                fullWidth
                value={participantData.degree}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row mt-4 gap-10">
              <TextField
                id="age"
                label="Age"
                variant="outlined"
                fullWidth
                value={participantData.age}
                onChange={handleChange}
              />
              <TextField
                id="semester"
                label="Current Semester"
                variant="outlined"
                fullWidth
                value={participantData.semester}
                onChange={handleChange}
              />
            </div>
          </div>
          <h2>
            <span>Files to be Uploaded</span>
          </h2>
          <div className="overflow-x-auto rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    File Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Upload File
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Format Accepted
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    File Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {event?.files_required?.map((item) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {item.fileName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <input
                        className="block w-full text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer
                      bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700
                      dark:border-gray-600 dark:placeholder-gray-400"
                        id={`file_input_${item.id}`}
                        type="file"
                        onChange={(e) => handleFileChange(e, item.id)}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.formats}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Tooltip title={item.description} placement="top-start">
                        <Button>Hover</Button>
                      </Tooltip>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Button
                        variant="contained"
                        onClick={() => handleUpload(item.id)}
                        disabled={
                          !fileState[item.id] || !fileState[item.id].file
                        }
                      >
                        Upload
                      </Button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {uploadedFiles.some((file) => file.id === item.id) ? (
                        <CloudDoneIcon className="text-green-500" />
                      ) : (
                        <CloudOffIcon className="text-red-500" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center items-center mt-4 p-2 rounded-md">
            <Button
              variant="contained"
              color="success"
              size="large"
              disabled={
                !participantData.collegeId ||
                !participantData.age ||
                !participantData.degree ||
                !participantData.semester ||
                uploadedFiles.length !== event?.files_required.length
              }
              onClick={handleApplyEvent}
              sx={{
                "&.Mui-disabled": {
                  bgcolor: "grey.400", // Change this to your desired color
                  color: "white", // Change text color if needed
                },
              }}
            >
              Send Application
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default EventParticipation;
