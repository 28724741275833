import React, { useState, useEffect } from "react";
import { Container } from "../common/Container";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../Utils/Utils";
import Loader from "../Loader";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ViewParticipants() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [openedApplicationId, setOpenedApplicationId] = useState();
  const [detailes, setDetailes] = useState({});
  // const [files, setFiles] = useState([]);
  const [updatedFiles, setUpdatedFiles] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [reSubmitComment, setReSubmitComment] = useState(
    "File submitted is incorrect"
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.alert("Please login to access this page.");
      navigate("/");
    }
  }, [navigate]);

  const { id } = useParams();

  const getApplicationsForHost = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_BASE_URL}/getApplicationsForHost`,
        {
          params: {
            event_id: id,
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        setApplicationList(response.data.data);
        // toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const getApplicationById = async (aId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_BASE_URL}/getDetailedApplicationById`,
        {
          params: {
            application_id: aId,
          },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        setOpenedApplicationId(response.data.data.application_id);
        setDetailes(response.data.data);
        // setFiles(response.data.data.files_submitted);
        setUpdatedFiles(response.data.data.files_submitted);
        // toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching application data:", error);
      setLoading(false);
      toast.error(error.message);
    }
  };

  const approveApplication = async (appId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/approveApplication`,
        { application_id: appId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        getApplicationById(appId);
        getApplicationsForHost();
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getApplicationsForHost();
  }, []);

  const handleDownload = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.setAttribute("download", "true");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAppove = async (index, status) => {
    const newFiles = updatedFiles.map((file, i) =>
      i === index ? { ...file, fileStatus: status } : file
    );
    setUpdatedFiles(newFiles);

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/resubmitFiles`,
        { application_id: openedApplicationId, files_submitted: newFiles },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        toast.success("Status updated");
        setLoading(false);
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const allFilesApproved = updatedFiles.every(
    (file) => file.fileStatus === "approved"
  );

  const handleStatusChange = (index, status) => {
    setCurrentIndex(index);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setReSubmitComment("");
  };

  const handleModalConfirm = async () => {
    if (reSubmitComment.trim() === "") {
      toast.error("Please enter a comment");
      return;
    }

    const newFiles = updatedFiles.map((file, i) =>
      i === currentIndex
        ? { ...file, fileStatus: "re_submit", reason: reSubmitComment }
        : file
    );
    setUpdatedFiles(newFiles);

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}/resubmitFiles`,
        { application_id: openedApplicationId, files_submitted: newFiles },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.status === 200) {
        toast.success("Status updated");
        setLoading(false);
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }

    handleModalClose(); // Close modal after action
  };

  const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;
  return (
    <div>
      {isLoading && <Loader fullScreen />}
      <Container>
        <p className="mb-4 font-semibold text-2xl">
          Event Name: {applicationList?.event_name || "NA"}
        </p>
        <div className="w-full flex gap-2">
          <div className="w-[30%] h-[70vh] overflow-y-auto">
            <TableContainer component={Paper}>
              <Table sx={{}} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Participant Name</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">DOS</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicationList?.applications?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No Data
                      </TableCell>
                    </TableRow>
                  ) : (
                    applicationList?.applications?.map((row, index) => (
                      <StyledTableRow
                        key={index}
                        className="cursor-pointer"
                        onClick={() => getApplicationById(row.application_id)}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.participant_name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.application_status}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {moment(row.created_at).format("D MMMM")}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="w-[58%] bg-white rounded-md p-4">
            {isEmptyObject(detailes) && (
              <p className="text-slate-700 font-semibold text-xl justify-center flex mt-10">
                Select application from left table
              </p>
            )}
            {!isEmptyObject(detailes) && (
              <>
                <div>
                  <div className="flex items-center mb-2">
                    <p className="text-2xl text-black font-semibold">Name: </p>
                    <p className="text-2xl text-black ml-4">
                      {detailes?.participant_name}
                    </p>
                  </div>
                  <div className="flex items-center mb-2">
                    <p className="text-2xl text-black font-semibold">Email: </p>
                    <p className="text-2xl text-black ml-4">
                      {detailes?.participant_email}
                    </p>
                  </div>
                  <div className="flex items-center mb-2">
                    <p className="text-2xl text-black font-semibold">Phone: </p>
                    <p className="text-2xl text-black ml-4">
                      {detailes?.participant_phone}
                    </p>
                  </div>
                  <div className="flex items-center mb-2">
                    <p className="text-2xl text-black font-semibold">
                      College Name:{" "}
                    </p>
                    <p className="text-2xl text-black ml-4">
                      {detailes?.participant_college}
                    </p>
                  </div>
                  <div className="flex items-center mb-2">
                    <p className="text-2xl text-black font-semibold">
                      Roll Number/Id Number:{" "}
                    </p>
                    <p className="text-2xl text-black ml-4">
                      {detailes?.id_number}
                    </p>
                  </div>
                  <div className="flex items-center mb-2">
                    <p className="text-2xl text-black font-semibold">
                      Degree/Course:{" "}
                    </p>
                    <p className="text-2xl text-black ml-4">
                      {detailes?.degree}
                    </p>
                  </div>
                  <div className="flex items-center mb-2">
                    <p className="text-2xl text-black font-semibold">Year: </p>
                    <p className="text-2xl text-black ml-4">
                      {detailes?.semester}
                    </p>
                  </div>
                </div>
                <div className="mt-4 border-t-2">
                  <div className="flex justify-between items-center">
                    <p className="font-semibold text-3xl text-black p-4">
                      Files Uploaded By Participant
                    </p>
                    <Button
                      variant="contained"
                      disabled={
                        !allFilesApproved ||
                        detailes.application_status === "approved"
                      }
                      onClick={() =>
                        approveApplication(detailes?.application_id)
                      }
                      color="success"
                    >
                      Approve Application
                    </Button>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>File Name</StyledTableCell>
                          <StyledTableCell align="left">
                            Download File
                          </StyledTableCell>
                          <StyledTableCell align="left">Status</StyledTableCell>
                          <StyledTableCell align="center">
                            Actions
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {updatedFiles.map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              {row.fileName}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <CloudDownloadIcon
                                fontSize="large"
                                color="primary"
                                className="cursor-pointer"
                                onClick={() => handleDownload(row.fileLink)}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.fileStatus}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <div className="flex justify-center gap-2">
                                <div>
                                  <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() =>
                                      handleAppove(index, "approved")
                                    }
                                    disabled={row.fileStatus !== "in_review"}
                                  >
                                    Approve
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() =>
                                      handleStatusChange(index, "re_submit")
                                    }
                                    disabled={row.fileStatus !== "in_review"}
                                  >
                                    Re-Submit
                                  </Button>
                                </div>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </div>
        </div>

        <Dialog
          open={openModal}
          onClose={handleModalClose}
          PaperProps={{
            style: {
              width: "500px",
              maxWidth: "90%",
            },
          }}
        >
          <DialogTitle>Re-Submit File</DialogTitle>
          <DialogContent>
            <p>Please enter a reason for re-submit</p>
            <TextField
              autoFocus
              margin="dense"
              label="Reason"
              type="text"
              fullWidth
              variant="outlined"
              value={reSubmitComment}
              onChange={(e) => setReSubmitComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleModalConfirm}
              variant="outlined"
              color="error"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default ViewParticipants;
