import React, { useEffect, useState, useCallback } from "react";
import styles from "./EventDetailForHost.module.scss";
import { Container } from "../common/Container";
import { Chrono } from "react-chrono";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import API_BASE_URL from "../../Utils/Utils";
import Loader from "../Loader";
const moment = require("moment");

const EventDetailForHost = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [rectifiedItems, setRectifiedItems] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [event, setEvent] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      window.alert("Please login to access this page.");
      navigate("/");
    }
  }, [navigate]);

  const getEvent = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/getEventByIdForHost`, {
        params: {
          event_id: id,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        setLoading(false);
        setEvent(response.data.data);
        // toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching event details:", error);
      toast.error("Error fetching event details");
    }
  }, [id]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  useEffect(() => {
    const transformedItems = event?.event_milestones?.map((item) => ({
      title: moment(item.dateTime).format("D MMM, HH:mm") + " hrs",
      cardTitle: item.heading,
      cardDetailedText: item.description,
      dateTime: item.dateTime,
    }));

    setRectifiedItems(transformedItems);

    if (transformedItems) {
      const today = moment();
      let closestPastDateIndex = null;
      let closestFutureDateIndex = null;

      transformedItems.forEach((item, index) => {
        const itemDate = moment(item.dateTime);
        if (itemDate.isSame(today, "day")) {
          closestPastDateIndex = index;
        }
        if (itemDate.isBefore(today)) {
          if (
            closestPastDateIndex === null ||
            itemDate.isAfter(
              moment(transformedItems[closestPastDateIndex].dateTime)
            )
          ) {
            closestPastDateIndex = index;
          }
        }
        if (itemDate.isAfter(today)) {
          if (
            closestFutureDateIndex === null ||
            itemDate.isBefore(
              moment(transformedItems[closestFutureDateIndex].dateTime)
            )
          ) {
            closestFutureDateIndex = index;
          }
        }
      });
      const highlightIndex =
        closestPastDateIndex !== null
          ? closestPastDateIndex
          : closestFutureDateIndex;
      setHighlightedIndex(highlightIndex !== null ? highlightIndex : 0);
    }
  }, [event]);

  const handleOpenInNewTab = () => {
    const url = `/view-participants/${id}`;
    window.open(url, "_blank");
  };

  return (
    <div className={styles.eventsDetailPage}>
      {isLoading && <Loader fullScreen />}
      <Container>
        <div className={styles.content}>
          <div className={styles.pageContent}>
            <div className={styles.detailContent}>
              <div className="flex items-end justify-end p-4">
                <Button variant="contained" onClick={handleOpenInNewTab}>
                  View Participants
                </Button>
              </div>
              <div className={styles.mainInfo}>
                <img
                  src={event.event_image}
                  alt="logo"
                  width={100}
                  height={100}
                />
                <div className={styles.eventTopInfo}>
                  <h2>{event.event_category || "NA"}</h2>
                  <p>{event.event_name}</p>
                  <div className="">
                    <p className="text-lg font-medium text-black">
                      {moment(event.event_start_date_time).format("MMM D")},{" "}
                      {moment(event.event_end_date_time).format("h:mm A")} -{" "}
                      {moment(event.event_start_date_time).format("MMM D")},{" "}
                      {moment(event.event_end_date_time).format("h:mm A")}
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.descriptionInfo}>
                <h2>Let&apos;s see, what {event.event_name} is all about.</h2>
                <p> {event.event_details}</p>
                <p>{event.others}</p>
                <p>Event Location: {event.event_location || "NA"}</p>
                <p>Event Prize: {event.event_prize || "NA"}</p>
                <p>Registration Fee: {event.registration_fees || "NA"}</p>
                <p>Registration Link: {event.registration_link || "NA"}</p>
                <p>Rule Book: {event.rule_book || "NA"}</p>
                <p>Sponsor Details: {event.sponsor_details || "NA"}</p>
                <p>Files Requested:</p>
                {event?.files_required?.map((value, index) => {
                  return (
                    <ul>
                      <li>
                        {value.fileName || "NA"}: {value.formats || "NA"}
                      </li>
                    </ul>
                  );
                })}
              </div>
              <div className={styles.opportunitiesInfo}>
                <h2 className="mt-6">
                  <span>Timeline View</span>
                </h2>
                <div>
                  {!!rectifiedItems && (
                    <Chrono
                      items={rectifiedItems}
                      mode="HORIZONTAL"
                      itemWidth={150}
                      activeItemIndex={highlightedIndex}
                      // showSingle
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EventDetailForHost;
