import React, { useState, useEffect } from "react";
import styles from './EventDetail.module.scss';
import { Container } from "../common/Container";
import { useNavigate } from "react-router-dom";

const EventDetails = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      window.alert('Please login to access this page.');
      navigate("/");
    }
  }, [navigate]);
  return (
      <div className={styles.eventsDetailPage}>
        <Container>
          <div className={styles.content}>
            <div className={styles.filter_list}>
              {/* <Dropdown>
                <DropdownTrigger>
                  <Button>College Festivals</Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem key="item1">Dance</DropdownItem>
                  <DropdownItem key="item2">Movies</DropdownItem>
                  <DropdownItem key="item3">Sports & Fitness</DropdownItem>
                </DropdownMenu>
              </Dropdown> */}
              <hr />
              <span className={styles.filled}>Dance</span>
              <span className={styles.filled}>Movies</span>
              <span className={styles.filled}>Sports & Fitness</span>
              <span className={styles.filled}>Cultural & Arts</span>
            </div>
            <div className={styles.pageContent}>
              <div className={styles.sidebarContent}>
                <div className={styles.eventCard}>
                  <a href='#'>
                    <img src="/Carousel.png" alt="logo" width={100} height={100} />
                    <div className={styles.eventTopInfo}>
                      <h2>Cultural Festivals</h2>
                      <p>Mood Indigo by IIT Bombay</p>
                      <div className={styles.eventInfo}>
                        <p>
                          <img src="/registered.svg" alt="logo" width={22} height={22} />
                          <span className={styles.registered}>244 registered</span></p>
                        <p>
                          <img src="/cup.svg" alt="logo" width={22} height={22} />
                          <span className={styles.competitions}>3 Competition(s)</span></p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className={styles.eventCard}>
                  <a href='#'>
                    <img src="/Carousel1.png" alt="logo" width={100} height={100} />
                    <div className={styles.eventTopInfo}>
                      <h2>Cultural Festivals</h2>
                      <p>Mood Indigo by IIT Bombay</p>
                      <div className={styles.eventInfo}>
                        <p>
                          <img src="/registered.svg" alt="logo" width={22} height={22} />
                          <span className={styles.registered}>244 registered</span></p>
                        <p>
                          <img src="/cup.svg" alt="logo" width={22} height={22} />
                          <span className={styles.competitions}>3 Competition(s)</span></p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className={styles.eventCard}>
                  <a href='#'>
                    <img src="/Carousel2.png" alt="logo" width={100} height={100} />
                    <div className={styles.eventTopInfo}>
                      <h2>Cultural Festivals</h2>
                      <p>Mood Indigo by IIT Bombay</p>
                      <div className={styles.eventInfo}>
                        <p>
                          <img src="/registered.svg" alt="logo" width={22} height={22} />
                          <span className={styles.registered}>244 registered</span></p>
                        <p>
                          <img src="/cup.svg" alt="logo" width={22} height={22} />
                          <span className={styles.competitions}>3 Competition(s)</span></p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className={styles.detailContent}>
                <div className={styles.mainInfo}>
                  <img src="/Carousel.png" alt="logo" width={100} height={100} />
                  <div className={styles.eventTopInfo}>
                    <h2>Cultural Festivals</h2>
                    <p>Mood Indigo by IIT Bombay</p>
                    <div className={styles.eventInfo}>
                      <p>
                        <img src="/users.svg" alt="logo" width={22} height={22} />
                        <span className={styles.registered}>244 registered</span></p>
                      <p>
                        <img src="/troffy.svg" alt="logo" width={22} height={22} />
                        <span className={styles.competitions}>3 Competition(s)</span></p>
                    </div>
                  </div>
                </div>
                <div className={styles.descriptionInfo}>
                  <h2>Let&apos;s see, what Optical Invasion is all about?</h2>
                  <p> containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    Why do we use it?</p>
                  <p>
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
                  </p>
                  <ul>
                    <li>lorem ispum dolloer</li>
                    <li>lorem ispum dolloer</li>
                    <li>lorem ispum dolloer</li>
                  </ul>
                </div>
                <div className={styles.opportunitiesInfo}>
                  <h2>Opportunities under cultural Festivals</h2>
                  <div className={styles.eventCardItems}>
                    <div className={styles.eventCard}>
                      <a href='#'>
                        <div className={styles.topContent}>
                          <img src="/Carousel.png" alt="logo" width={100} height={100} />
                          <div className={styles.eventTopInfo}>
                            <h2>Cultural Festivals</h2>
                            <p>Mood Indigo by IIT Bombay</p>
                            <div className={styles.eventInfo}>
                              <p>
                                <img src="/light-users.svg" alt="logo" width={22} height={22} />
                                <span className={styles.registered}>244 registered</span></p>
                              <p>
                                <img src="/light-cup.svg" alt="logo" width={22} height={22} />
                                <span className={styles.competitions}>3 Competition(s)</span></p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.bottomContent}>
                          <span>Online quiz</span>
                          <span>Viva</span>
                        </div>
                      </a>
                    </div>
                    <div className={styles.eventCard}>
                      <a href='#'>
                        <div className={styles.topContent}>
                          <img src="/Carousel1.png" alt="logo" width={100} height={100} />
                          <div className={styles.eventTopInfo}>
                            <h2>Cultural Festivals</h2>
                            <p>Mood Indigo by IIT Bombay</p>
                            <div className={styles.eventInfo}>
                              <p>
                                <img src="/light-users.svg" alt="logo" width={22} height={22} />
                                <span className={styles.registered}>244 registered</span></p>
                              <p>
                                <img src="/light-cup.svg" alt="logo" width={22} height={22} />
                                <span className={styles.competitions}>3 Competition(s)</span></p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.bottomContent}>
                          <span>Online quiz</span>
                          <span>Viva</span>
                        </div>
                      </a>
                    </div>
                    <div className={styles.eventCard}>
                      <a href='#'>
                        <div className={styles.topContent}>
                          <img src="/Carousel2.png" alt="logo" width={100} height={100} />
                          <div className={styles.eventTopInfo}>
                            <h2>Cultural Festivals</h2>
                            <p>Mood Indigo by IIT Bombay</p>
                            <div className={styles.eventInfo}>
                              <p>
                                <img src="/light-users.svg" alt="logo" width={22} height={22} />
                                <span className={styles.registered}>244 registered</span></p>
                              <p>
                                <img src="/light-cup.svg" alt="logo" width={22} height={22} />
                                <span className={styles.competitions}>3 Competition(s)</span></p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.bottomContent}>
                          <span>Online quiz</span>
                          <span>Viva</span>
                        </div>
                      </a>
                    </div>
                    <div className={styles.eventCard}>
                      <a href='#'>
                        <div className={styles.topContent}>
                          <img src="/Carousel.png" alt="logo" width={100} height={100} />
                          <div className={styles.eventTopInfo}>
                            <h2>Cultural Festivals</h2>
                            <p>Mood Indigo by IIT Bombay</p>
                            <div className={styles.eventInfo}>
                              <p>
                                <img src="/light-users.svg" alt="logo" width={22} height={22} />
                                <span className={styles.registered}>244 registered</span></p>
                              <p>
                                <img src="/light-cup.svg" alt="logo" width={22} height={22} />
                                <span className={styles.competitions}>3 Competition(s)</span></p>
                            </div>
                          </div>
                        </div>
                        <div className={styles.bottomContent}>
                          <span>Online quiz</span>
                          <span>Viva</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
  );
};

export default EventDetails;