import React from "react";
import styles from "./Homepage.module.scss";
import { Container } from "../common/Container";
import { Button } from "../common/Button";

const Banner = () => {
  return (
    <div className={styles.bannerPage}>
      <img
        src="/banner.jpg"
        width={1022}
        height={583}
        alt="banner"
        className={styles.banner}
      />
      <Container>
        <div className={styles.content}>
          <h2>
            Elevate Your College <br /> Experience with Effortless <br />
            <span> Event Management</span>
          </h2>
          <p>
            Discover, Plan, and Celebrate Unforgettable Campus Moments with
            Ease. Transform Campus Events into Powerful Opportunities for
            College Visibility and Seamless Management.
          </p>
          {/* <div>
            <Button label="Login To Participate" />
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default Banner;
